import axios from 'axios'
import Vue from "vue";

// 添加请求拦截器
axios.interceptors.request.use(config => {
    // 在发送请求之前做些什么
    config.headers.Authorization = `app_guid=2f762a2aac21a204`
    config.apiUrl = config.url
    config.url = `https://api.zoomcaretech.com` + config.url
    return config;
}, error => {
    console.log("触发请求拦截错误");
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(response => {
    if (response.data.code !== 200) {
        Vue.$toast.error(`接口数据响应错误\nApi：${response.config.apiUrl}\n错误代码：${response.data.code}\n错误消息：${response.data.msg}`)
        return Promise.reject(response.data);
    } else {
        return response.data;
    }
}, error => {
    // response.status超出 2xx 范围的状态码都会触发该函数。
    if (error.config && error.config.apiUrl) {
        Vue.$toast.error(`请求接口数据响应错误\nApi：${error.config.apiUrl}\n错误代码：${error.response.data.code}\n错误消息：${error.response.data.msg}`)
    } else {
        console.log(error);
        Vue.$toast.error(`请求服务器错误`)
    }
    return Promise.reject(error);
});

export default axios